import {
  AfterContentInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';

import {
  Company,
  Filter,
  urlIdentifierForCompany,
} from 'company-finder-common';
import { ComponentBase } from '../_component.base';
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context';
import { AppComponent } from '../../../app/app.component';
import { SearchService } from '../../services/search/search.service';

@Component({
  selector: 'company-tags',
  templateUrl: './company-tags.component.html',
  styleUrls: ['./company-tags.component.scss'],
})
export class CompanyTagsComponent
  extends ComponentBase
  implements AfterContentInit, OnDestroy
{
  @Input()
  public company: Company;
  @Input()
  public tags: string[];
  // Different usages of this component provide different widths for the tags.
  // We don't want to always elide, and we want different places to elide at
  // different times. This threshold is essentially the width of the tags
  // container, but a value of -1 means do not elide at all, as would be
  // desirable on the company details screen.
  @Input()
  public elideThreshold: number;
  @Input()
  public useWhiteBackground: false;
  @Input()
  public marginTopForWrapping = 5;
  @Input()
  public tagDisplayType: 'bubbles' | 'list' = 'bubbles';

  // 8 is used as an approximation for the pixel width of a character elsewhere (e.g. sector-map.component).
  // Dynamic layout of tags (ADJQ-284) required some margin for error
  private readonly fontWidth = 8.5;
  private readonly padding = 5;
  private readonly margin = 5;
  private readonly moreBoxWidth = 42;
  private readonly elidedContainerWidth = 60;

  public displayedTags: string[] = [];

  constructor(
    private _app: AppComponent,
    private _router: Router,
    private ref: ElementRef<HTMLElement>,
    dc: DeploymentContext,
    private searchService: SearchService
  ) {
    super(dc);
  }

  ngAfterContentInit(): void {
    if (!!this._app?.resizeSubject) {
      this.addSubscription(
        this._app.resizeSubject.subscribe(() => {
          this.refreshProperties();
        })
      );

      this.refreshProperties();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  public refreshProperties(): void {
    if (this.elideThreshold !== -1) {
      this.elideThreshold =
        this.ref.nativeElement.getBoundingClientRect().width;
    }
    this.getDisplayedTags();
  }

  public get marginForWrapping(): Partial<CSSStyleDeclaration> {
    if (this.elideThreshold === -1) {
      return { marginTop: `${this.marginTopForWrapping}px` };
    }
  }

  public get maxWidth(): number | undefined {
    // The elideThreshold should also be considered the max width of
    // the tags component unless it is explicitly -1
    return this.elideThreshold === -1 ? undefined : this.elideThreshold;
  }

  /**
   * If there are more tags than can fit in the display area, returns the n most common and n least common
   */
  public getDisplayedTags(): void {
    // An elideThreshold of -1 indicates all tags should be displayed, taking up as much room as needed
    this.displayedTags =
      this.elideThreshold === -1 || !this.elideThreshold
        ? this.tags
        : this.fitTagsInAvailableWidth(
            0,
            this.tags.length - 1,
            this.elideThreshold
          );
  }

  public get hiddenTagsCount(): number {
    return this.tags.length - this.displayedTags.length;
  }

  public elisionClasses(tag: string): string {
    return this.shouldElide(tag) ? 'tag-elide' : 'tag-full';
  }

  public handleClick(company: Company): void {
    this._router.navigate(['company', urlIdentifierForCompany(company.name)]);
  }

  public get elideSet(): boolean {
    return this.elideThreshold > -1;
  }

  public get hasHiddenTags(): boolean {
    return this.hiddenTagsCount > 0;
  }

  public tagClicked(tag: string): void {
    // From other places we search with the drilldownSubject, but that won't
    // work here since the company detail page doesn't have a filter component
    // so there is nothing listening. Instead, we set the filter and navigate
    this.searchService.filter = new Filter({ tags: [tag] });
    this.searchService.navigateToSearchResults();
  }

  public shouldElide(tag: string): boolean {
    if (this.elideThreshold === -1) {
      return false;
    }

    const tagWidth = tag.length * this.fontWidth;
    let tagsWidth = 0;
    this.tags.forEach((t) => {
      tagsWidth += this.getPixelWidth(t);
      // plus the 26px more box if necessary.
      if (this.tags.length > this.displayedTags.length) {
        tagsWidth += this.moreBoxWidth;
      }
    });
    return (
      tagsWidth > this.elideThreshold && tagWidth > this.elidedContainerWidth
    );
  }

  /**
   * Determine which tags to display. If not all tags can fit in the available width, the n
   * most common and n least common tags will be displayed. This method assumes the tags are
   * passed to the component in some sorted order, and iterates over the array from the outside
   * in to determine the correct set of tags.
   * @param lowIndex An index from the beginning of the array. Increases with each iteration.
   * @param highIndex An index from the end of the array. Decreases with each iteration.
   * @param availableWidth Available space in the layout for remaining tags.
   */
  private fitTagsInAvailableWidth(
    lowIndex: number,
    highIndex: number,
    availableWidth: number
  ): string[] {
    if (lowIndex > highIndex) {
      // if our indices are out of order, we have reached the base of recursion
      return [];
    }

    // Try to place the more common tag within the space allowed
    const moreCommonTag = this.tags[lowIndex];
    availableWidth = availableWidth - this.getPixelWidth(moreCommonTag);
    // ensure room for the "more" box if needed
    if (availableWidth - this.moreBoxWidth > 0) {
      // If there is still room left over, and the less common tag is not the same as the more common tag,
      // try to fit in the less common tag
      const lessCommonTag = this.tags[highIndex];
      availableWidth = availableWidth - this.getPixelWidth(lessCommonTag);
      if (availableWidth - this.moreBoxWidth > 0 && lowIndex !== highIndex) {
        // if there's still room left, try to fit in the next more common tag and less common tag
        return [moreCommonTag]
          .concat(
            this.fitTagsInAvailableWidth(
              lowIndex + 1,
              highIndex - 1,
              availableWidth
            )
          )
          .concat(lessCommonTag);
      }

      // if there's not room for the less common tag, or if the less common tag is actually the same as the more common tag,
      // return the more common tag
      return [moreCommonTag];
    }

    // neither of the two tags can fit
    return [];
  }

  private getPixelWidth(tag: string) {
    // Add up the width of the strings,
    // plus the padding on each side,
    // plus the right margin
    return tag.length * this.fontWidth + 2 * this.padding + this.margin;
  }
}
