import { Component, Input } from '@angular/core';
import { ComponentBase } from '../../../../_common/components/_component.base';
import { Company, CompanyKey } from 'company-finder-common';

@Component({
  selector: 'blue-knight-milestones',
  templateUrl: './blue-knight-milestones.component.html',
  styleUrls: ['./blue-knight-milestones.component.scss'],
})
export class BlueKnightMilestonesComponent extends ComponentBase {
  @Input()
  public company: Company;

  public properties: CompanyKey[] = [
    'commercialPartnerships',
    'rAndDPartnerships',
    'otherPartnerships',
    'challenges',
    'priorities',
    'compositionOfMatter',
    'securedIP',
    'filedIP',
    'addtlIPDetails',
    'fundingStage',
    'fundingStageDetails',
    'nonDilutiveFunding',
    'dilutiveFunding',
    'majorMilestones',
    'currentPharmaStage',
    'currentMedTechStage',
    'rndStageDetails',
    'regulatoryStatus',
    'anticipatedCommercialProductYr',
    'nonLeadRegStatus',
    'entryExitStrategy',
  ];

  public get hasData(): boolean {
    return !this.properties.some((property) => !!this.company[property]);
  }
}
